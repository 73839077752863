import React from 'react'
import { MediaItem, Link } from '../../../../plugins/gatsby-plugin-atollon'
import useAuctionProperties from "../../../hooks/use-auction-properties"
import { graphql } from 'gatsby'
import AddToCalendar from '@culturehq/add-to-calendar'
import moment from 'moment'

const UpcomingAuctions = ({ title }) => {

  const properties = useAuctionProperties()

  return (
    <>
      <section className='upcoming'>
        <div className='upcoming__header'>
          <div className='upcoming__inner'>
            <h1>{ title }</h1>
          </div>
        </div>
        <div className='upcoming__body'>
          <div className='upcoming__inner'>
            <div className='upcoming__items'>
              { properties?.map((el, i) => {
                const { address, auctionDateTime, images, permalink } = el
                if (moment(auctionDateTime).isBefore(new Date())) return null
                const event = {
                  name: `${address} - Property Auction`,
                  details: `Auction @ ${address}`,
                  location: address,
                  startsAt: moment(auctionDateTime),
                  endsAt: moment(auctionDateTime).add(1, 'hour'),
                }
                return (
                  <div className='upcoming__item' key={i}>
                    <div className='upcoming__image'>
                      <Link to={permalink}>
                        {images.length > 0 && <MediaItem item={images[0].primary[0]} />}
                      </Link>
                    </div>
                    <h4 className='upcoming__title'>
                      <Link to={permalink}>{address}</Link>
                    </h4>
                    <div className='upcoming__meta'>
                      <span>Auction</span>
                      <AddToCalendar event={event}>
                        {moment(auctionDateTime).format('ddd D MMM — h:mm A')}
                      </AddToCalendar>
                    </div>
                  </div>
                )
              }) }
            </div>
            { properties?.length === 0 && <p className='upcoming__no-results'>No listings at this time.</p> }
          </div>
        </div>
      </section>
    </>
  )
}

export default UpcomingAuctions

export const query = graphql`
fragment UpcomingAuctionsProps on DatoCmsUpcomingAuction {
  ... on DatoCmsUpcomingAuction {
    title
  }
}
`